<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                        <b-overlay :show="isLoading">
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(addForm)" @reset.prevent="reset">
                                        <b-row>
                                            <b-col lg="6" md="6" sm="6">
                                                <ValidationProvider name="Mill Name" vid="mill_info_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="mill_info_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('bsri_config.mill_name') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="subzoneSetup.mill_info_id"
                                                        :options="millNameList"
                                                        id="mill_info_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="6">
                                                <ValidationProvider name="Subzone Office Name (EN)" vid="subzone_office_name_en" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="subzone_office_name_en"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('bsri_config.subzone_office_name_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        id="subzone_office_name_en"
                                                        v-model="subzoneSetup.subzone_office_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <b-col lg="6" md="6" sm="6">
                                                    <ValidationProvider name="Subzone Office Name (BN)" vid="subzone_office_name_bn" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="subzone_office_name_bn"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_config.subzone_office_name_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="subzone_office_name_bn"
                                                            v-model="subzoneSetup.subzone_office_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            <b-col xl="6" lg="6" sm="6">
                                                <ValidationProvider name="Description (En)" vid="description_en">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="description_en"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{$t('bsri_config.description_en')}}
                                                        </template>
                                                        <b-form-textarea
                                                            id="description_en"
                                                            v-model="subzoneSetup.description"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="6">
                                                <ValidationProvider name="Description (Bn)" vid="description_bn">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="description_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{$t('bsri_config.description_bn')}}
                                                        </template>
                                                        <b-form-textarea
                                                            id="description_bn"
                                                            v-model="subzoneSetup.description_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                            <ValidationProvider name="Office Type" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.office_type_id"
                                                :options="officeTypeList"
                                                id="office_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12">
                                        <ValidationProvider name="area_type_id" vid="area_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="area_type_id "
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.area_type_id"
                                                id="area_type_id"
                                                :options="getAreaTypeList"
                                                @change="getAreaTypeData(subzoneSetup.area_type_id)"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-if="ItemShow">
                                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.division_id"
                                                :options="divisionList"
                                                id="division_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12" v-if="ItemShow">
                                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-if="PauroshobaItemShow || UnionItemShow">
                                        <ValidationProvider name="Upazila" vid="upazila_id" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazila_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.upazilla_id"
                                                :options="upazilaList"
                                                id="upazila_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-if="UnionItemShow">
                                        <ValidationProvider name="Union" vid="union_id" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="union_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.union_id"
                                                :options="unionList"
                                                id="union_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-if="CityCorpItemShow">
                                        <ValidationProvider name="City Corporation" vid="city_corporation_id" v-show="CityCorpItemShow" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="city_corporation_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.city_corporation_id"
                                                :options="cityCorporationList"
                                                id="city_corporation_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-if="PauroshobaItemShow">
                                        <ValidationProvider name="Municipality" vid="pauroshoba_id" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="pauroshoba_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.pauroshoba_id"
                                                :options="pauroshobaList"
                                                id="pauroshoba_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="6" sm="12" v-if="ItemShow">
                                        <ValidationProvider name="Ward" vid="ward_id" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="ward_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('org_pro.ward') }} <span class="text-danger"></span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="subzoneSetup.ward_id"
                                                :options="wardList"
                                                id="ward_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                        </b-row>
                                        <b-row>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{$t('bsri_config.add_more')}}</b-button>
                                            </div>
                                        </b-row>
                                    </b-form>
                                </ValidationObserver>
                                            <hr>
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                        <b-overlay :show="unitLoad">
                                            <b-row style="border-bottom:1px solid #ddd; margin-bottom:10px">
                                                <b-col xl="12" lg="12" sm="12">
                                                    <b-table-simple bordered>
                                                        <thead class="text-white bg-success">
                                                            <tr>
                                                                <th class="text-center mytd" >{{ $t('globalTrans.sl_no') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_config.mill_name') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_config.subzone_office_name') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('bsri_config.subzone_office_name_bn') }}</th>
                                                                <th class="mytd" scope="col">{{ $t('globalTrans.action') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <b-tbody>
                                                            <b-tr v-for="(item, index) in subzoneObj.subzoneSetupList" :key="index">
                                                                <b-td class="text-center mytd">{{ $n(index + 1) }}</b-td>
                                                                <b-td class="mytd">{{ getMillName(item.mill_info_id) }}</b-td>
                                                                <b-td class="mytd">
                                                                    <ValidationProvider name="Subzone Office Name (EN)" vid="subzone_office_name_en" rules="required">
                                                                        <b-form-group
                                                                            slot-scope="{ valid, errors }"
                                                                        >
                                                                            <b-form-input
                                                                            id="subzone_office_name_en"
                                                                            v-model="item.subzone_office_name"
                                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                                            ></b-form-input>
                                                                            <div class="invalid-feedback d-block" v-if="allErrors['subzoneSetupList.'+ index + '.subzone_office_name']">
                                                                            {{ allErrors['subzoneSetupList.'+ index + '.subzone_office_name'][0] ? 'Subzone office name already taken' : '' }}
                                                                            </div>
                                                                        </b-form-group>
                                                                    </ValidationProvider>
                                                                </b-td>
                                                                <b-td class="mytd">
                                                                    <ValidationProvider name="Subzone Office Name (BN)" vid="subzone_office_name_bn" rules="required">
                                                                        <b-form-group
                                                                            slot-scope="{ valid, errors }"
                                                                        >
                                                                            <b-form-input
                                                                            id="subzone_office_name_bn"
                                                                            v-model="item.subzone_office_name_bn"
                                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                                            ></b-form-input>
                                                                            <div class="invalid-feedback d-block" v-if="allErrors['subzoneSetupList.'+ index + '.subzone_office_name_bn']">
                                                                            {{ allErrors['subzoneSetupList.'+ index + '.subzone_office_name_bn'][0] ? 'Subzone office name already taken' : '' }}
                                                                            </div>
                                                                        </b-form-group>
                                                                    </ValidationProvider>
                                                                </b-td>
                                                                <b-td class="mytd">
                                                                    <b-button @click="remove(item)" type="button" variant="danger">X</b-button>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr v-if="nodata">
                                                                <td colspan="5" class="text-center">
                                                                    <span class="text-black">{{ $t('globalTrans.noDataFound') }}</span>
                                                                </td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                        </b-overlay>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-overlay>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { subzoneOfficeStore, officeEntryStoresubzone, officeEntryStoresubzonevalidate } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            nodata: true,
            isLoading: false,
            unitLoad: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            addBtnName: this.$t('bsri_config.addBtn'),
            ItemShow: false,
            CityCorpItemShow: false,
            PauroshobaItemShow: false,
            UnionItemShow: false,
            subzoneSetup: {
                mill_type_id: 0,
                mill_info_id: 0,
                subzone_office_name: '',
                subzone_office_name_bn: '',
                description: '',
                description_bn: '',
                org_id: 8,
                office_type_id: '0',
                area_type_id: '0',
                city_corporation_id: '0',
                pauroshoba_id: '0',
                ward_id: '0',
                country_id: 1,
                division_id: '0',
                district_id: '0',
                upazilla_id: '0',
                union_id: '0',
                office_name: '',
                office_name_bn: '',
                office_code: '1',
                parent_office_type_id: '0',
                parent_office_id: '0',
                created_by: 1,
                updated_by: 1
            },
            subzoneObj: {
                subzoneSetupList: []
            },
            millNameList: [],
            allErrors: {},
            upazilaList: [],
            unionList: [],
            cityCorporationList: [],
            pauroshobaList: [],
              office: {
                org_id: '0',
                office_type_id: '0',
                area_type_id: '0',
                city_corporation_id: '0',
                pauroshoba_id: '0',
                ward_id: '0',
                country_id: 1,
                division_id: '0',
                district_id: '0',
                upazilla_id: '0',
                union_id: '0',
                office_name: '',
                office_name_bn: '',
                office_code: '1',
                parent_office_type_id: '0',
                parent_office_id: '0',
                created_by: 1,
                updated_by: 1
            },
            officeTypeList: [],
            districtList: [],
            wardList: []
        }
    },
    created () {
        this.officeTypeList = this.getOfficeTypeList()
        if (this.id) {
            alert(this.id)
            const tmp = this.getSubzoneOfficeData()
            this.subzoneSetup = tmp
            this.millNameList = this.getMillNameList()
        }
        this.millNameList = this.getMillNameList()
    },
    watch: {
        'subzoneSetup.mill_type_id': function (newValue) {
            this.millNameList = this.getMillNameList(newValue)
        },
        'subzoneSetup.org_id': function (newVal, oldVal) {
            this.officeTypeList = this.getOfficeTypeList(newVal)
        },
        'subzoneSetup.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'subzoneSetup.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'subzoneSetup.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'subzoneSetup.city_corporation_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByCityCorportaion(newVal)
        },
        'subzoneSetup.pauroshoba_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByPauroshoba(newVal)
        },
        'subzoneSetup.union_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByUnion(newVal)
        },
        'subzoneSetup.subzone_office_name': function () {
          this.subzoneSetup.office_name = this.subzoneSetup.subzone_office_name
        },
        'subzoneSetup.subzone_office_name_bn': function () {
          this.subzoneSetup.office_name_bn = this.subzoneSetup.subzone_office_name_bn
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        millTypeList: function () {
            const objectData = this.$store.state.incentiveGrant.commonObj.millTypeList.filter(item => item.status === 1)
            return objectData.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        grantTypeList: function () {
            return this.grant_types.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        }
    },
    methods: {
        getOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === 8)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === 8).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
            getWardListByCityCorportaion (cityCorpId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByUnion (unionId) {
            const objectData = this.$store.state.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getSubzoneOfficeData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        getMillNameList (id) {
            const dataObject = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
            return dataObject.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        getMillName (id) {
            const objectData = this.$store.state.incentiveGrant.commonObj.millInfoList.find(item => item.value === id)
            if (this.$i18n.locale === 'bn') {
                return objectData.text_bn
            } else {
                return objectData.text_en
            }
        },
        remove (index) {
            this.subzoneObj.subzoneSetupList.splice(index, 1)
        },
        async addForm () {
            this.unitLoad = true
            if (this.subzoneSetup.mill_info_id !== 0 && this.subzoneSetup.subzone_office_name !== '' && this.subzoneSetup.subzone_office_name_bn !== '') {
                const subName = this.subzoneSetup.subzone_office_name
                const subNameBn = this.subzoneSetup.subzone_office_name_bn
                const millNameId = this.subzoneSetup.mill_info_id
                const existData = this.subzoneObj.subzoneSetupList.find(item => item.mill_info_id === millNameId && item.subzone_office_name === subName && item.subzone_office_name_bn === subNameBn)
                if (typeof existData === 'undefined') {
                    this.subzoneSetup.org_id = 8
                    await RestApi.postData(commonServiceBaseUrl, officeEntryStoresubzonevalidate, this.subzoneSetup).then(response => {
                    if (response.success) {
                    this.subzoneObj.subzoneSetupList.push(this.subzoneSetup)
                    this.unitLoad = false
                    } else {
                    this.$toast.error({
                    title: 'error',
                    message: 'This subzone already exist',
                    color: '#D6E09B'
                    })
                    this.unitLoad = false
                    }
                    })
                    this.subzoneSetup = {}
                } else {
                    this.subzoneSetup.mill_info_id = 0
                    this.subzoneSetup.subzone_office_name = ''
                    this.subzoneSetup.subzone_office_name_bn = ''
                    this.subzoneSetup.description = ''
                    this.subzoneSetup.description_bn = ''
                    this.$toast.error({
                        title: 'Error',
                        message: this.$i18n.locale === 'bn' ? 'তথ্য ইতিমধ্যেই বিদ্যমান!' : 'Data already exists!'
                    })
                    this.unitLoad = false
                }
                this.$nextTick(() => {
                    this.$refs.form1.reset()
                })
                this.nodata = false
            }
        },
        async saveData () {
            this.isLoading = true
            this.unitLoad = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }
            await RestApi.postData(commonServiceBaseUrl, officeEntryStoresubzone, this.subzoneObj).then(response => {
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (response.success) {
                let i = 0
                for (i = 0; i < response.id.length; i++) { this.subzoneObj.subzoneSetupList[i].office_id = response.id[i] }
                    RestApi.postData(incentiveGrantServiceBaseUrl, subzoneOfficeStore, this.subzoneObj).then(response => {
                    this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                    this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                    })
                    this.unitLoad = false
                    this.$bvModal.hide('modal-4')
                    this.isLoading = false
                    })
                } else {
                    this.isLoading = false
                    this.unitLoad = false
                    this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'The Subzone office already been taken',
                    color: '#D6E09B'
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
    .mytd{
        padding: 0.25rem 0.75rem 0.25rem 0.75rem !important;
    }
</style>
